import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { set } from 'date-fns';
import {
  BarFill,
  BarTimer,
  ButtonContainer,
  CloseButton,
  CommentContainer,
  Container,
  Header,
  RateContainer,
  StarContainer,
  Title,
} from './styles';
import {
  CrossIcon,
  LogoRobotIcon,
  StarIcon,
} from '../../../assets/svg/SVGComponents';
import PrimaryButton from '../../Buttons/PrimaryButton';
import SecondaryButton from '../../Buttons/SecondaryButton';
import {
  IFeedbacksProps,
  IUpdateFeedbackProps,
} from '../../../dtos/IFeedbacksProps';
import { useFeedback } from '../../../hooks/feedback';
import { IPopoverComponentProps } from '../../../dtos/IPopoverComponentsProps';

const Popover: React.FC<IPopoverComponentProps> = ({
  positiontop,
  positionleft,
  style,
  closePopover,
  openPopover,
  setTimer = true,
}) => {
  const [rating, setRating] = useState(0);
  const [selectRating, setSelectRating] = useState(0);
  const [commentText, setCommentText] = useState('');
  const [progress, setProgress] = useState(0);
  const [timerStopped, setTimerStopped] = useState(false);
  const { feedbacks, updateFeedback } = useFeedback();
  const [isTimeout, setIsTimeout] = useState(false);
  const feedbackSource = 'large-popover';
  const timerRef = useRef<number | null>(null);
  const progressRef = useRef<number>(0);
  const { t } = useTranslation();
  const [isHaveComment, setIsHaveComment] = useState(false);

  const memoizedClosePopover = useCallback(() => {
    closePopover();
  }, [closePopover]);

  const memoizedSetProgress = useCallback(
    (value: number) => {
      setProgress(value);
    },
    [setProgress],
  );

  const memoizedSetIsTimeout = useCallback(
    (value: boolean) => {
      setIsTimeout(value);
    },
    [setIsTimeout],
  );

  const memoFeedback = useMemo(() => {
    if (feedbacks) {
      setRating(feedbacks.rating || 0);
      setSelectRating(feedbacks.rating || 0);
      setCommentText(feedbacks.comment || '');
    }

    return feedbacks;
  }, [feedbacks]);

  useEffect(() => {
    if (memoFeedback.comment && memoFeedback.comment.length > 3) {
      setIsHaveComment(true);
    } else {
      setIsHaveComment(false);
    }
  }, [memoFeedback.comment]);
  // Memoizar as funções

  useEffect(() => {
    const duration = 4000;
    const intervalTime = 50;
    const totalSteps = duration / intervalTime;
    const progressIncrement = 100 / totalSteps;

    if (openPopover && setTimer && !timerStopped) {
      progressRef.current = 0;
      memoizedSetProgress(0);
      memoizedSetIsTimeout(false);

      timerRef.current = window.setInterval(() => {
        progressRef.current += progressIncrement;

        if (progressRef.current >= 100) {
          progressRef.current = 100;
          memoizedSetProgress(100);
          memoizedClosePopover();
          memoizedSetIsTimeout(true);

          if (timerRef.current !== null) {
            clearInterval(timerRef.current);
            timerRef.current = null;
          }
        } else {
          memoizedSetProgress(progressRef.current);
        }
      }, intervalTime);
    }

    return () => {
      if (timerRef.current !== null) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [
    openPopover,
    setTimer,
    timerStopped,
    memoizedClosePopover,
    memoizedSetProgress,
    memoizedSetIsTimeout,
  ]);

  // Efeito para parar o timer se 'timerStopped' mudar para true
  useEffect(() => {
    if (timerStopped) {
      if (timerRef.current !== null) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
      memoizedSetProgress(100); // Garante que o progresso seja mostrado como completo
    }
  }, [timerStopped, memoizedSetProgress]);

  const handleClick = useCallback((starIndex: number) => {
    setRating(starIndex);
  }, []);

  const handleStopTimer = (): void => {
    setTimerStopped(true); // Interrompe o temporizador
  };

  // Todos os dados que foram atualizados vai ser alterados aqui
  const changeData = useCallback(() => {
    const updateFeedbackData: IUpdateFeedbackProps = {
      rating,
      feedback_source: feedbackSource,
      comment: commentText,
    };

    updateFeedback(updateFeedbackData);
  }, [commentText, rating, updateFeedback]);

  useEffect(() => {
    if (isTimeout) {
      if (rating !== feedbacks?.rating) {
        changeData();
        setTimerStopped(false);
      }
    }
  }, [changeData, feedbacks?.rating, isTimeout, rating]);

  const handleSendFeedback = useCallback(() => {
    changeData();
    setTimerStopped(false);
    setProgress(0);
    closePopover();
  }, [changeData, closePopover]);

  const handleClosePopover = useCallback(() => {
    closePopover();
    setTimerStopped(false);
  }, [closePopover]);

  return (
    <Container
      style={style}
      positiontop={positiontop}
      positionleft={positionleft}
    >
      <BarTimer>
        <BarFill style={{ width: `${100 - progress}%` }} />
      </BarTimer>
      <Header>
        <Title>
          <LogoRobotIcon width="2.21rem" fill="#1e88e5" />
          <h1>{t('h1.feedback')}</h1>
        </Title>
        <CloseButton type="button" onClick={() => handleClosePopover()}>
          <CrossIcon width="1.21rem" strokeWidth={1.5} stroke="#000" />
        </CloseButton>
      </Header>

      <RateContainer>
        <h3>{t('h3.how_feedback_works')}</h3>
        <StarContainer>
          {Array.from({ length: 5 }, (_, index) => (
            <StarIcon
              key={index}
              width="2.67rem"
              onClick={() => handleClick(index + 1)}
              fill={index < selectRating ? '#ff9800' : '#e2e2e2'}
              onMouseEnter={() => setSelectRating(index + 1)}
              onMouseLeave={() => setSelectRating(rating)}
            />
          ))}
        </StarContainer>
      </RateContainer>

      <CommentContainer>
        <h3>
          {isHaveComment ? (
            <Trans i18nKey="h3.thank_you" />
          ) : (
            <Trans i18nKey="h3.we_are_happy" />
          )}
        </h3>
        <textarea
          placeholder="Escreva seu comentário"
          onClick={handleStopTimer}
          value={commentText}
          onChange={e => setCommentText(e.target.value)}
        />
      </CommentContainer>

      <ButtonContainer>
        <PrimaryButton onClick={() => handleSendFeedback()}>
          Enviar
        </PrimaryButton>
        <SecondaryButton onClick={() => handleClosePopover()}>
          Cancelar
        </SecondaryButton>
      </ButtonContainer>
    </Container>
  );
};

export default Popover;
