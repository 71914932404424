/* eslint-disable react/jsx-indent */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSpring, useTransition } from 'react-spring';
import { ArrowBackIcon } from '../../../assets/svg/SVGComponents';
import 'react-step-progress-bar/styles.css';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

import {
  Content,
  HeaderMainContent,
  HeaderWrapper,
  MainContent,
  ProgressBarWrapper,
  ActivityTitle,
  DisplayChapter,
  ContentWrapper,
  ChapterTitle,
  NoContentContainer,
  ButtonWrapper,
} from './styles';
import IRequestError from '../../../dtos/IRequestError';
import { useAuth } from '../../../hooks/auth';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import Popover from '../../../components/PopoverContainer/Popover';
import IChapterProps from '../../../dtos/IChapterProps';
import IStepProps from '../../../dtos/IStepsProps';
import IActivityProps from '../../../dtos/IActivitiesProps';
import SecondaryButton from '../../../components/Buttons/SecondaryButton';
import ITrailProps from '../../../dtos/ITrailsProps';
import ProgressBarTutorial from '../../../components/ProgressBarTutorial';
import MarkdownComponent from './MarkdownComponent';
import MakecodeFrame from './MakecodeFrame';
import SlideComponent from './SlideComponent';
import { ResourceType } from '../../../utils/enums';
import EmbeddingComponent from './EmbeddingComponent';
import LoadingState from './LoadingState';
import { useFeedback } from '../../../hooks/feedback';
import { ICreateFeedbackProps } from '../../../dtos/IFeedbacksProps';
import { IPopoverSideProps } from '../../../dtos/IPopoverComponentsProps';
import VideoComponent from './VideoComponent';

enum StepType {
  INTRO = 'intro',
  BUILDING = 'building',
  CIRCUIT = 'circuit',
  CODING = 'coding',
  BEHIND = 'behind-the-code',
  SIMULATOR = 'simulator',
  LEARN = 'what-did-you-learn',
}

interface ProgressBarProps {
  total_steps: number;
  steps: ProgressBarStepsProps[];
  progress: number;
  current_step: ProgressBarStepsProps;
}
interface ProgressBarStepsProps {
  progress: number;
  order: number;
  step: IStepProps;
}

const Tutorial: React.FC = () => {
  const { isSessionExpired, updateUser, user } = useAuth();

  const { t } = useTranslation();
  const { addToast } = useToast();
  const { trail_slug, chapter_id, activity_slug } = useParams();
  const backButtonRef = useRef<HTMLButtonElement>(null);
  const secondaryButtonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const [isChapterNext, setIsChapterNext] = useState(false);
  const [isChapterBack, setIsChapterBack] = useState(false);
  const [trailData, setTrailData] = useState<ITrailProps>();
  const [chapterData, setChapterData] = useState<IChapterProps>();
  const [currentActivity, setCurrentActivity] = useState<IActivityProps>();
  const [stepsData, setSteps] = useState<IStepProps[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [progressBarSteps, setProgressBarSteps] = useState<ProgressBarProps>();
  const [currentStep, setCurrentStep] = useState<ProgressBarStepsProps>();
  const [currentStepData, setCurrentStepData] = useState<IStepProps | null>();
  const [nextButtonStepDisabled, setNextButtonStepDisable] = useState(true);
  const [backButtonStepDisabled, setBackButtonStepDisable] = useState(true);
  const [nextButtonActivityDisabled, setNextButtonDisable] = useState(true);
  const [backButtonActivityDisabled, setBackButtonDisable] = useState(true);
  const { openFeedback, setOpenFeedback, feedbacks, handleFeedback } =
    useFeedback();
  const [interaction, setInteraction] = useState(0);
  const [positiontop, setPositionTop] = useState(30);
  const [positionleft, setPositionLeft] = useState(40);
  const [showPopover, setShowPopover] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);

  useEffect(() => {
    api
      .get(`/chapters/${trail_slug}/${chapter_id}/${user.id}`)
      .then(response => {
        setTrailData(response.data.trail);
        setChapterData(response.data.chapters);
        setIsLoading(false);
      })
      .catch(async (err: IRequestError) => {
        if (!(await isSessionExpired(err))) {
          addToast({
            type: 'error',
            expression: 'Ops!',
            description: t('toasts.errors.load_data_fail'),
          });
        }
      });
  }, [addToast, t, user.id, chapter_id, trail_slug]);
  // nao coloque isSessionExpired no array de dependencias

  const handleNavigateIsActivityOrChapter = useCallback(() => {
    if (trailData && currentActivity && chapterData) {
      const { activities } = chapterData;

      const currentActivityIndex = activities.findIndex(
        activity => activity.id === currentActivity.id,
      );

      if (trailData.type === 'activity') {
        setIsChapterNext(false);
        setIsChapterBack(false);
        if (currentActivityIndex >= chapterData.activities.length - 1) {
          setIsChapterNext(true);
        }
        if (currentActivityIndex <= 0) {
          setIsChapterBack(true);
        }
      } else if (trailData.type === 'chapter') {
        setIsChapterNext(true);
        setIsChapterBack(true);
      }
    }
  }, [chapterData, currentActivity, trailData]);

  useEffect(() => {
    handleNavigateIsActivityOrChapter();
  }, [handleNavigateIsActivityOrChapter]);

  useEffect(() => {
    if (chapterData) {
      const activityData = chapterData.activities.find(
        activity => activity.slug === activity_slug,
      );
      if (activityData) {
        setCurrentActivity(activityData);
        const feedbackData: ICreateFeedbackProps = {
          activity_id: activityData.id,
        };
        handleFeedback(feedbackData);
      }
    }
  }, [activity_slug, chapterData]);

  // configurar barra de progresso
  useEffect(() => {
    const activeTab = StepType.INTRO;
    const stepsBar: ProgressBarProps = {
      total_steps: 0,
      steps: [],
      progress: 0,
      current_step: { step: {} as IStepProps, progress: 0, order: 0 },
    };

    if (stepsData && activeTab) {
      stepsBar.total_steps = stepsData.length;

      const percentConclusion = 100 / (stepsBar.total_steps - 1);
      for (let x = 0; x < stepsData.length; x += 1) {
        stepsBar.steps.push({
          step: stepsData[x],
          progress: percentConclusion * x,
          order: x + 1,
        });
      }

      setCurrentStep(
        stepsBar.steps.find(stepData => stepData.step.step_type === activeTab),
      );
    } else if (stepsData) {
      const stepsActivities = stepsData;
      stepsBar.total_steps = stepsData.length;

      const percentConclusion = 100 / (stepsBar.total_steps - 1);
      for (let x = 0; x < stepsData.length; x += 1) {
        stepsBar.steps.push({
          step: stepsActivities[x],
          progress: percentConclusion * x,
          order: x + 1,
        });
      }

      setCurrentStep(stepsBar.steps[0]);
    }
    setProgressBarSteps(stepsBar);
  }, [stepsData]);

  // mudar de step pelos botões
  const handleNavigateStep = useCallback(
    (direction: string) => {
      if (progressBarSteps) {
        const currentIndex = progressBarSteps.steps.findIndex(
          step => step.step === currentStep?.step,
        );
        const nextIndex =
          direction === 'next' ? currentIndex + 1 : currentIndex - 1;

        if (nextIndex >= 0 && nextIndex < progressBarSteps.steps.length) {
          setCurrentStep(progressBarSteps.steps[nextIndex]);
        }
      }
    },
    [currentStep, progressBarSteps],
  );

  // current step data
  useEffect(() => {
    if (stepsData && currentStep) {
      const stepData = stepsData.find(step => step.id === currentStep.step.id);
      if (stepData) setCurrentStepData(stepData);
      else setCurrentStepData(null);
    }
  }, [stepsData, currentStep]);

  // habitar ou desabitar botões de voltar ou avancar passos
  useEffect(() => {
    if (currentStep && progressBarSteps) {
      if (currentStep.order <= 1) {
        setBackButtonStepDisable(true);
      } else {
        setBackButtonStepDisable(false);
      }

      if (currentStep.order >= progressBarSteps.steps.length) {
        setNextButtonStepDisable(true);
      } else {
        setNextButtonStepDisable(false);
      }
    }
  }, [currentStep, progressBarSteps]);

  // api.post para enviar atividade concluida
  const handleEndActivity = useCallback(async () => {
    if (currentActivity) {
      const activity_id = currentActivity.id;
      const data = { activity_id };

      api
        .post(`/activities/user-progress`, data)
        .then(async response => {
          updateUser(response.data);
        })
        .catch(async (err: IRequestError) => {
          if (!(await isSessionExpired(err))) {
            addToast({
              type: 'error',
              expression: 'Ops!',
              description: t('toasts.errors.update_progress_fail'),
            });
          }
        });
    }
  }, [addToast, currentActivity, isSessionExpired, t, updateUser]);

  // useEffect para enviar finalização da atividade
  useEffect(() => {
    if (currentStep && progressBarSteps) {
      if (currentStep.order >= progressBarSteps.steps.length) {
        handleEndActivity();
      }
    }
  }, [currentStep, handleEndActivity, progressBarSteps]);

  // configurar steps da atividade
  useEffect(() => {
    if (currentActivity) {
      setSteps(currentActivity.steps);
    }
  }, [currentActivity]);

  const getNextChapter = useCallback(() => {
    if (trailData && chapterData) {
      const currentChapter = trailData.chapters.find(
        ch => ch.id === chapterData.id,
      );
      if (currentChapter) {
        const currentIndex = trailData.chapters.findIndex(
          ch => ch.id === currentChapter.id,
        );

        if (currentIndex < trailData.chapters.length - 1) {
          return trailData.chapters[currentIndex + 1];
        }
      }
    }
    return null;
  }, [chapterData, trailData]);

  const getPreviousChapter = useCallback(() => {
    if (trailData && chapterData) {
      const currentChapter = trailData.chapters.find(
        ch => ch.id === chapterData.id,
      );
      if (currentChapter) {
        const currentIndex = trailData.chapters.findIndex(
          ch => ch.id === currentChapter.id,
        );

        if (currentIndex > 0) {
          return trailData.chapters[currentIndex - 1];
        }
      }
    }
    return null;
  }, [chapterData, trailData]);

  // mudar para próxima atividade ou próximo capitulo
  const handleNavigateButton = useCallback(
    (direction: string) => {
      if (trailData && chapterData && currentActivity && progressBarSteps) {
        const { activities } = chapterData;
        const { chapters } = trailData;

        const currentIndexActivity = activities.findIndex(
          activity => activity.id === currentActivity.id,
        );
        const currentIndexChapter = chapters.findIndex(
          chapter => chapter.id === chapterData.id,
        );

        const nextIndexActivity =
          direction === 'next'
            ? currentIndexActivity + 1
            : currentIndexActivity - 1;
        const nextIndexChapter =
          direction === 'next'
            ? currentIndexChapter + 1
            : currentIndexChapter - 1;
        if (trailData.type === 'activity') {
          if (
            nextIndexActivity >= 0 &&
            nextIndexActivity < chapterData.activities.length
          ) {
            setCurrentActivity(activities[nextIndexActivity]);
          } else if (isChapterNext) {
            const nextChapter = getNextChapter();

            if (nextChapter) {
              setChapterData(nextChapter);
              setCurrentActivity(nextChapter.activities[0]);
            }
          } else if (isChapterBack) {
            const previousChapter = getPreviousChapter();

            if (previousChapter) {
              setChapterData(previousChapter);
              setCurrentActivity(previousChapter.activities[0]);
            }
          }
        }
        if (trailData.type === 'chapter') {
          if (
            nextIndexChapter >= 0 &&
            nextIndexChapter < trailData.chapters.length
          ) {
            const nextChapter = trailData.chapters[nextIndexChapter];

            if (nextChapter) {
              setChapterData(nextChapter);
              setCurrentActivity(nextChapter.activities[0]);
            }
          }
        }
        setCurrentStep(
          progressBarSteps.steps.find(
            step => step.step.step_type === StepType.INTRO,
          ),
        );
      }
    },
    [
      chapterData,
      currentActivity,
      getNextChapter,
      getPreviousChapter,
      isChapterBack,
      isChapterNext,
      progressBarSteps,
      trailData,
    ],
  );

  // ativar desativar botões de navegação
  useEffect(() => {
    if (currentActivity && trailData && chapterData) {
      const { activities } = chapterData;
      const { chapters } = trailData;

      const currentActivityIndex = activities.findIndex(
        activity => activity.id === currentActivity.id,
      );

      const currentChapterIndex = trailData.chapters.findIndex(
        chapter => chapter.id === chapterData.id,
      );

      if (currentActivityIndex > 0 || currentChapterIndex > 0) {
        setBackButtonDisable(false);
      } else {
        setBackButtonDisable(true);
      }

      if (
        currentActivityIndex < activities.length - 1 ||
        currentChapterIndex < chapters.length - 1
      ) {
        setNextButtonDisable(false);
      } else {
        setNextButtonDisable(true);
      }
    }
  }, [chapterData, currentActivity, trailData]);

  const handleFinalizeActivity = useCallback(async () => {
    if (currentActivity) {
      const activity_id = currentActivity.id;
      const data = { activity_id };
      api
        .post(`/activities/user-progress`, data)
        .then(async response => {
          updateUser(response.data);
        })
        .catch(async (err: IRequestError) => {
          if (!(await isSessionExpired(err))) {
            addToast({
              type: 'error',
              expression: 'Ops!',
              description: t('toasts.errors.update_progress_fail'),
            });
          }
        });
      navigate(`/trails/${trail_slug}/chapters`);
    }
  }, [
    addToast,
    currentActivity,
    isSessionExpired,
    navigate,
    t,
    trail_slug,
    updateUser,
  ]);
  // não atualize a o array de dependencias com o isSessionExpires

  useEffect(() => {
    if (chapterData && currentActivity && currentStep) {
      navigate(
        `/projects/${trail_slug}/${chapterData.id}/activity/${currentActivity.slug}`,
        { replace: true },
      );
    }
  }, [currentActivity, currentStep, navigate, trail_slug]);
  // não atualize com o chapter data;

  const handleAccesVideoButton = useCallback(() => {
    if (currentActivity) {
      if (currentActivity.resource_url != null) {
        navigate(currentActivity.resource_url);
      }
    }
  }, [currentActivity, navigate]);

  // contabiliza a interação por usuário
  const handleInteraction = useCallback(() => {
    setInteraction(prevCount => prevCount + 1);
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleInteraction);
    return () => {
      document.removeEventListener('click', handleInteraction);
    };
  }, [handleInteraction, interaction]);

  // abrir popover de feedback
  const handleOpenPopover = useCallback(
    (side: 'left' | 'right' | 'center'): void => {
      if (feedbacks.activity_id !== currentActivity?.id) {
        return;
      }

      if (!openFeedback) {
        setOpenPopover(true);
        setShowPopover(true);

        if (side === 'left') {
          setPositionLeft(0);
          setPositionTop(30);
        } else if (side === 'right') {
          setPositionLeft(70);
          setPositionTop(30);
        } else if (side === 'center') {
          setPositionLeft(40);
          setPositionTop(50);
        }
      }
    },
    [currentActivity?.id, feedbacks.activity_id, openFeedback],
  );

  // fechar popover de feedback
  const handleClosePopover = useCallback(() => {
    setShowPopover(false);
    setOpenPopover(false);
    setOpenFeedback(false);
  }, [setOpenFeedback]);

  const feedbackPopUp = useSpring({
    transform: showPopover ? 'scale(1)' : 'scale(0)',
    config: { tension: 500 },
  });

  return (
    <ContentWrapper>
      {!!isLoading && <LoadingState />}
      {!isLoading && (
        <Content>
          <HeaderWrapper>
            <Popover
              positiontop={positiontop}
              positionleft={positionleft}
              closePopover={() => handleClosePopover()}
              style={feedbackPopUp}
              openPopover={openPopover}
            />
            <HeaderMainContent>
              <button
                type="button"
                ref={backButtonRef}
                onMouseEnter={() => handleOpenPopover('left')}
              >
                <Link to={-1 as any}>
                  <ArrowBackIcon
                    width="1.22rem"
                    stroke="#1E88E5"
                    strokeWidth={2.5}
                    fill="none"
                  />
                  {t('navlinks.back')}
                </Link>
              </button>

              <ChapterTitle>
                {!isLoading && chapterData && (
                  <DisplayChapter>
                    <h1>{t(`chapters.${chapterData.slug}.title`)}</h1>
                  </DisplayChapter>
                )}
                {!isLoading && currentActivity && (
                  <ActivityTitle>
                    <PrimaryButton
                      type="button"
                      disabled={backButtonActivityDisabled}
                      onClick={() => handleNavigateButton('back')}
                    >
                      {isChapterBack
                        ? t(`buttons.back_button_chapter`)
                        : t(`buttons.back_button_activity`)}
                    </PrimaryButton>

                    <h2>{t(`activities.${currentActivity.slug}.title`)}</h2>

                    <PrimaryButton
                      type="button"
                      disabled={nextButtonActivityDisabled}
                      onClick={() => handleNavigateButton('next')}
                    >
                      {isChapterNext
                        ? t(`buttons.next_button_chapter`)
                        : t(`buttons.next_button_activity`)}
                    </PrimaryButton>
                  </ActivityTitle>
                )}
              </ChapterTitle>
              <SecondaryButton
                onClick={handleFinalizeActivity}
                type="button"
                ref={secondaryButtonRef}
                onMouseEnter={() => handleOpenPopover('right')}
              >
                {t('buttons.finalize_button')}
              </SecondaryButton>
            </HeaderMainContent>

            <ProgressBarWrapper>
              {!!chapterData &&
                currentActivity &&
                currentStep &&
                progressBarSteps && (
                  <>
                    <button
                      disabled={backButtonStepDisabled}
                      type="button"
                      onClick={() => handleNavigateStep('back')}
                    >
                      <ArrowBackIcon
                        width="1.22rem"
                        stroke="#1E88E5"
                        strokeWidth={2.5}
                        fill="none"
                      />
                      {t('buttons.back_button_progressbar')}
                    </button>
                    <div>
                      <ProgressBarTutorial
                        total_steps={progressBarSteps.total_steps}
                        steps={progressBarSteps.steps}
                        current_step={currentStep}
                      />
                    </div>
                    <button
                      type="button"
                      disabled={nextButtonStepDisabled}
                      onClick={() => handleNavigateStep('next')}
                    >
                      {t('buttons.next_button_progressbar')}
                      <ArrowBackIcon
                        width="1.22rem"
                        stroke="#1E88E5"
                        strokeWidth={2.5}
                        fill="none"
                        transform="scale(-1, 1)"
                      />
                    </button>
                  </>
                )}
            </ProgressBarWrapper>
          </HeaderWrapper>
          <MainContent>
            {!!chapterData && currentActivity && (
              <>
                {currentActivity.type === 'lesson' && (
                  <NoContentContainer>
                    <img
                      alt="404"
                      src="https://fuzzyplaydev.s3.us-east-2.amazonaws.com/image-404-gray.jpg"
                    />
                    <ButtonWrapper>
                      {currentActivity &&
                        currentActivity.resource_url != null && (
                          <PrimaryButton
                            type="button"
                            onClick={handleAccesVideoButton}
                          >
                            Acesso ao Vídeo
                          </PrimaryButton>
                        )}
                    </ButtonWrapper>
                  </NoContentContainer>
                )}
                {currentStepData && (
                  <>
                    {currentStepData.resourse_type ===
                      ResourceType.MAKECODE && (
                      <MakecodeFrame
                        currentActivity={currentActivity}
                        currentStep={currentStepData}
                      />
                    )}

                    {currentStepData.resourse_type ===
                      ResourceType.MARKDOWN && (
                      <MarkdownComponent
                        currentActivity={currentActivity}
                        currentStep={currentStepData}
                      />
                    )}

                    {currentStepData.resourse_type === ResourceType.SLIDE && (
                      <SlideComponent
                        currentActivity={currentActivity}
                        currentStep={currentStepData}
                      />
                    )}
                    {currentStepData.resourse_type ===
                      ResourceType.EMBEDING && (
                      <EmbeddingComponent
                        currentActivity={currentActivity}
                        currentStep={currentStepData}
                      />
                    )}
                    {currentStepData.resourse_type === ResourceType.VIDEO && (
                      <VideoComponent
                        currentActivity={currentActivity}
                        currentStep={currentStepData}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </MainContent>
        </Content>
      )}
    </ContentWrapper>
  );
};
export default Tutorial;
