import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MakecodeIframe, Container } from './styles';
import { IContextChaptersProps } from '../../../../dtos/IChapterProps';
import IActivityProps from '../../../../dtos/IActivitiesProps';
import { ResourceType, StepType } from '../../../../utils/enums';
import IStepProps from '../../../../dtos/IStepsProps';

type FrameState =
  | 'loading'
  | 'no-project'
  | 'opening-project'
  | 'project-open'
  | 'closing-project';

interface MakeCodeFrameState {
  frameState: FrameState;
  loadPercent?: number; // Progress bar load % from 0 - 100 (loaded)
  workspaceReady: boolean;
  pendingShare: boolean;
}

interface IComponentProps {
  currentActivity: IActivityProps;
  currentStep: IStepProps;
}

const MakecodeFrame: React.FC<IComponentProps> = ({
  currentStep: step,
  currentActivity,
}) => {
  const iFrameRef = useRef<HTMLIFrameElement>(null);

  const [makecodeUrl, setMakecodeUrl] = useState(' ');
  const location = useLocation();
  const slice = location.pathname.split('/');
  const active_tab = slice[slice.length - 1] as StepType;

  const loadTutorialLink = useCallback(
    async (currentStep: IStepProps) => {
      if (!currentStep) return;
      if (currentStep && step.resourse_type === ResourceType.MAKECODE)
        if (currentStep) setMakecodeUrl(currentStep.resource_url);
    },
    [step],
  );

  useEffect(() => {
    if (step) loadTutorialLink(step);
  }, [loadTutorialLink, step]);

  useEffect(() => {
    if (iFrameRef.current && iFrameRef.current.contentWindow) {
      window.addEventListener('message', event => {
        // console.log(event);
      });
    }
  }, []);

  return (
    <Container>
      {step && (
        <>
          <MakecodeIframe>
            <iframe
              src={`${makecodeUrl}?controller=1&noproject=1&nocookiebanner=1&ws=browser`}
              title="test pxt"
              ref={iFrameRef}
            />
          </MakecodeIframe>
        </>
      )}
    </Container>
  );
};

export default MakecodeFrame;
