import React from 'react';
import IActivityProps from '../../../../dtos/IActivitiesProps';
import IStepProps from '../../../../dtos/IStepsProps';
import { Container } from './styles';

// import { Container } from './styles';

interface IComponentProps {
  currentActivity: IActivityProps;
  currentStep: IStepProps;
}

const VideoComponent: React.FC<IComponentProps> = ({
  currentStep: step,
  currentActivity,
}) => {
  return (
    <Container>
      <iframe
        title="panda-96686bbc-e03e-4ae2-8981-e5c1046eaed7"
        id="panda-96686bbc-e03e-4ae2-8981-e5c1046eaed7"
        src={step?.resource_url}
        allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
        allowFullScreen
        width="100%"
        height="100%"
      />
    </Container>
  );
};

export default VideoComponent;
